import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import { addDays, subDays } from 'date-fns'
import { default as DatePicker } from 'react-datepicker'

import {
  Flex,
  Input,
  Button,
  Text,
  Stack,
  SimpleGrid,
  Textarea,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react'

import 'react-datepicker/dist/react-datepicker.css'

function FormQuote({
  hideOnSuccess,
  onSubmit,
  name,
  email,
  message,
  checkIn,
  checkOut,
  successText,
  buttonText,
}) {
  const [success, setSuccess] = useState(false)
  const formik = useFormik({
    initialValues: { name: '', email: '', message: '', checkIn: null, checkOut: null },
    validate: (values) => {
      const errors = {}

      if (!values.email) {
        errors.email = email.required
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = email.invalid
      }

      if (!values.name) {
        errors.name = name.required
      }

      if (!values.checkIn) {
        errors.checkIn = checkIn.required
      }

      if (!values.checkOut) {
        errors.checkOut = checkOut.required
      }

      return errors
    },
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values, (success) => setSuccess(success))
      setSubmitting(false)
    },
  })

  if (hideOnSuccess && success) {
    return (
      <Flex padding={10} justify="center">
        <Text fontWeight="bold" fontSize="xl">
          {successText}
        </Text>
      </Flex>
    )
  }

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    errors,
    values,
    isSubmitting,
    setFieldValue,
  } = formik

  return (
    <form onSubmit={handleSubmit}>
      <SimpleGrid columns={{ base: 1, md: 2 }} width="100%" direction="column" spacing={4}>
        <FormControl isRequired isInvalid={errors.name && touched.name}>
          <FormLabel htmlFor="name">{name.label}</FormLabel>
          <Input
            variant="filled"
            type="name"
            id="name"
            placeholder={name.placeholder}
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <FormErrorMessage>{errors.name}</FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={errors.email && touched.email}>
          <FormLabel htmlFor="email">Email</FormLabel>
          <Input
            variant="filled"
            placeholder={email.placeholder}
            type="email"
            id="email"
            aria-describedby="email-helper-text"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <FormErrorMessage>{errors.email}</FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={errors.checkIn && touched.checkIn}>
          <FormLabel htmlFor="checkIn">{checkIn.label}</FormLabel>
          <DatePicker
            id="checkIn"
            autoComplete="off"
            customInput={<Input variant="filled" />}
            selected={values.checkIn}
            minDate={addDays(new Date(), 1)}
            maxDate={values.checkOut === null ? null : subDays(values.checkOut, 1)}
            onChange={(e) => setFieldValue('checkIn', e)}
            onBlur={handleBlur}
          />
          <FormErrorMessage>{errors.checkIn}</FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={errors.checkOut && touched.checkOut}>
          <FormLabel htmlFor="checkOut">{checkOut.label}</FormLabel>
          <DatePicker
            id="checkOut"
            autoComplete="off"
            customInput={<Input variant="filled" />}
            selected={values.checkOut}
            minDate={values.checkIn === null ? addDays(new Date(), 1) : addDays(values.checkIn, 1)}
            onChange={(e) => setFieldValue('checkOut', e)}
            onBlur={handleBlur}
          />
          <FormErrorMessage>{errors.checkOut}</FormErrorMessage>
        </FormControl>
      </SimpleGrid>

      <Stack spacing={6} marginTop={6}>
        <FormControl isInvalid={errors.message && touched.message}>
          <FormLabel htmlFor="message">{message.label}</FormLabel>
          <Textarea
            variant="filled"
            id="message"
            value={values.message}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={message.placeholder}
          />
          <FormErrorMessage>{errors.message}</FormErrorMessage>
        </FormControl>
        <Button colorScheme="primary" size="lg" type="submit" isDisabled={isSubmitting}>
          {buttonText}
        </Button>
      </Stack>
    </form>
  )
}

FormQuote.propTypes = {
  hideOnSuccess: PropTypes.bool,
  onSuccess: PropTypes.func,
}

FormQuote.defaultProps = {
  hideOnSuccess: false,
  onSuccess: () => {},
}

export default FormQuote
