import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Global, css, useTheme } from '@emotion/react'
import { Link, useTranslation, useI18next } from 'gatsby-plugin-react-i18next'
import { useRecoilState } from 'recoil'
import { MdLanguage } from 'react-icons/md'

import { Flex, Button, Stack } from '@chakra-ui/react'

import {
  Footer,
  FooterBody,
  FooterColumn,
  FooterDivider,
  FooterBottom,
  Main,
  P,
  BookingModal,
  WhatsappButton,
  Popup,
  Modal,
  H1,
  H2,
} from '../ui'

import SocialLinks from './socialLinks'
import Header from './header'
import FloatingBook from './floatingBook'

import { bookingModalState } from '../store'
import SEO from './seo'
import ButtonBook from './buttonBook'

import logoFooter from '../images/logo.png'
import FormQuote from './formQuote'
import { v4 } from 'uuid'
import { format } from 'date-fns'

const globalStyles = css`
  @font-face {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-display: swap;
    font-weight: 400;
  }

  @font-face {
    font-family: Poppins, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Crimson Text', serif;
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  * {
    font-size: 14px;
    font-family: Poppins, Arial, Helvetica, sans-serif;
    font-weight: 400;

    @media (min-width: 1024px) {
      font-size: 16px;
    }
  }

  h2 {
    font-family: Poppins !important;
  }

  h1,
  h3 {
    font-family: 'Crimson Text', Arial, Helvetica, sans-serif !important;
    font-weight: 400 !important;
  }

  p,
  span {
    font-size: 16px;
    max-height: 999999px;
    opacity: 0.85;
  }
`

const onSubmit = async (values, setSuccess) => {
  const body = {
    ...values,
    checkIn: format(values.checkIn, 'MMMM, dd, yyyy'),
    checkOut: format(values.checkOut, 'MMMM, dd, yyyy'),
    to: 'info@hotelhaciendapoxila.com, obravo@hmnagency.com',
  }

  const res = await fetch(`https://form-server.vercel.app/api/reservation`, {
    headers: {
      Accept: 'application/json',
    },
    method: 'post',
    body: JSON.stringify(body),
  })

  if (res.ok) setSuccess(true)
  else setSuccess(false)
}

function Layout({
  children,
  title,
  variantHeader,
  disableHeader,
  disableWhatsapp,
  disablePopup,
  disableFloatingBanner,
}) {
  const isCoyote = process.env.GATSBY_HOTEL_VAR === 'casa-coyote'

  const { t } = useTranslation()
  const { language, changeLanguage } = useI18next()
  const [bookingModal, setBookingModal] = useRecoilState(bookingModalState)

  const theme = useTheme()

  return (
    <>
      <Global styles={globalStyles} />
      <SEO title={`${title}`} />

      {!disableHeader && <Header variantHeader={variantHeader} />}
      <Flex direction="column" minHeight="100vh">
        <Main>{children}</Main>
      </Flex>
      <Footer borderBottom={`4px solid ${theme.colors.secondary[800]}`}>
        <FooterBody>
          <FooterColumn align="center">
            <Link to="/">
              <Flex width={{ base: '250px', md: '300px' }}>
                <img src={logoFooter} />
              </Flex>
            </Link>
            <P>{t('common:footer.desc')}</P>
            <Flex>
              <Button
                colorScheme="black"
                variant="outline"
                leftIcon={<MdLanguage />}
                onClick={() => changeLanguage(language === 'es' ? 'en' : 'es')}
                _hover={{ background: 'transparent' }}
              >
                {language === 'es' ? 'Change to English' : 'Cambiar a Español'}
              </Button>
            </Flex>
          </FooterColumn>

          <FooterColumn />

          <FooterColumn title={t('common:footer.contact')}>
            <SocialLinks />
            <Link to="/privacy">
              <P fontSize="sm" textDecoration="underline">
                Política de Privacidad
              </P>
            </Link>
          </FooterColumn>
        </FooterBody>
        <FooterDivider backgroundColor="blackAlpha.500" />
        <FooterBottom direction={{ base: 'column', md: 'row' }} justify="space-between">
          <P fontSize="sm" lineHeight="shorter" textAlign={{ base: 'center', lg: 'left' }}>
            {t('common:footer.terms')}
          </P>
          <P fontSize="sm" lineHeight="shorter" textAlign={{ base: 'center', lg: 'left' }}>
            Developed by{' '}
            <P
              fontSize="sm"
              as="a"
              href="https://www.hmnagency.com"
              textDecoration="underline"
              target="_blank"
            >
              hmnagency.com
            </P>
          </P>
        </FooterBottom>
      </Footer>

      {!disableWhatsapp && (
        <WhatsappButton
          bottom="75px"
          href={`https://api.whatsapp.com/send?phone=529984753813&text=Hola, gracias por contactar a Global Jet Set, ¿Cuál es tu solicitud?...`}
        />
      )}

      {/* {!disableFloatingBanner && (
        <FloatingBook
          title={t('common:floatingBanner.title')}
          desc={t('common:floatingBanner.desc')}
          colorScheme="red"
        />
      )} */}

      {/* {!disablePopup && (
        <Popup>
          <ButtonBook
            width="100%"
            height="100%"
            promotion="lastminute"
            arrival="2021-02-15"
            departure="2021-02-16"
          >
            <img
              style={{ width: '100%' }}
              src={
                language === 'es'
                  ? isCoyote
                    ? popupEsCoyote
                    : popupEsXaman
                  : isCoyote
                  ? popupEnCoyote
                  : popupEnXaman
              }
            />
          </ButtonBook>
        </Popup>
      )} */}

      <Modal
        isOpen={bookingModal.isOpen}
        onClose={() => setBookingModal((obj) => ({ ...obj, isOpen: false }))}
      >
        <Stack maxWidth="560px" marginX="auto">
          <H1>{t('common:form.title')}</H1>
          <P>{t('common:form.subtitle')}</P>

          <FormQuote
            hideOnSuccess
            onSubmit={onSubmit}
            name={{
              label: t('common:form.name.label'),
              required: t('common:form.name.required'),
              invalid: t('common:form.name.invalid'),
              placeholder: t('common:form.name.placeholder'),
            }}
            email={{
              required: t('common:form.email.required'),
              invalid: t('common:form.email.invalid'),
              placeholder: t('common:form.email.placeholder'),
            }}
            checkIn={{
              label: t('common:form.checkIn.label'),
              required: t('common:form.checkIn.required'),
              invalid: t('common:form.checkIn.invalid'),
              placeholder: t('common:form.checkIn.placeholder'),
            }}
            checkOut={{
              label: t('common:form.checkOut.label'),
              required: t('common:form.checkOut.required'),
              invalid: t('common:form.checkOut.invalid'),
              placeholder: t('common:form.checkOut.placeholder'),
            }}
            message={{
              label: t('common:form.message.label'),
              placeholder: t('common:form.message.placeholder'),
            }}
            successText={t('common:form.successText')}
            buttonText={t('common:form.sendMessage')}
          />
        </Stack>
      </Modal>

      {/* <BookingModal
        isOpen={bookingModal.isOpen}
        onClose={() => setBookingModal((obj) => ({ ...obj, isOpen: false }))}
        url={`https://rbe.zaviaerp.com/${bookingModal.promotion}?hotel=casacoyote&arrival=${
          bookingModal.arrival
        }&departure=${bookingModal.departure}&lng=${language === 'es' ? 'es' : 'en'}&currency=${
          language === 'es' ? 'mxn' : 'usd'
        }`}
      /> */}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  disableHeader: PropTypes.bool,
  disableWhatsapp: PropTypes.bool,
  disablePopup: PropTypes.bool,
  disableFloatingBanner: PropTypes.bool,
  title: PropTypes.string,
  variantHeader: PropTypes.oneOf(['solid', 'transparent']),
}

Layout.defaultProps = {
  disableHeader: false,
  disableWhatsapp: false,
  disablePopup: false,
  disableFloatingBanner: false,
  title: '',
  variantHeader: 'transparent',
}

export const query = graphql`
  fragment fixedImage on File {
    childImageSharp {
      fixed(width: 680, quality: 65) {
        ...GatsbyImageSharpFixed
      }
    }
  }

  fragment bannerSectionImage on File {
    childImageSharp {
      fluid(maxWidth: 1860, quality: 65) {
        ...GatsbyImageSharpFluid
      }
    }
  }

  fragment multipleFixedImage on FileConnection {
    edges {
      node {
        childImageSharp {
          fixed(width: 680, quality: 65) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`

export default Layout
