import React from 'react'
import { Button } from '@chakra-ui/react'

function ButtonBook({ children, ...restProps }) {
  return (
    <Button
      borderRadius="3px"
      background="linear-gradient(90deg, rgba(179,155,27,1) 0%, rgba(244,225,124,1) 50%, rgba(179,155,27,1) 100%) !important"
      textTransform="uppercase"
      letterSpacing="1px"
      {...restProps}
    >
      {children}
    </Button>
  )
}

export default ButtonBook
