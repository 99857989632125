import React from 'react'
import { useTranslation, Link } from 'gatsby-plugin-react-i18next'
import PropTypes from 'prop-types'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { Button, Flex } from '@chakra-ui/react'

import { Menu, MenuLink } from '../ui'

import ButtonBook from './buttonBook'

import logo from '../images/logo-white.png'
import logoFooter from '../images/logo.png'

function Header({ variantHeader, ...restProps }) {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Menu
      borderBottom={`1px solid rgba(255, 255, 255, 0.25)`}
      logo={
        <Link to="/">
          <Flex height={{ base: '35px', md: '50px' }} justifyContent={{ lg: 'center' }}>
            <img style={{ height: '100%', width: 'auto' }} src={logo} />
          </Flex>
        </Link>
      }
      zIndex={2}
      // rightButton={
      //   <Button as="a" href="/#services" variant="solid" colorScheme="secondary">
      //     {t('common:viewServices')}
      //   </Button>
      // }
      backgroundColor="transparent"
      position="absolute"
      {...restProps}
    >
      <MenuLink
        opacity="0.95"
        color="white"
        textTransform="uppercase"
        as={Link}
        to="/"
        fontWeight="bold"
      >
        {t('common:nav.home')}
      </MenuLink>
      <MenuLink
        opacity="0.95"
        color="white"
        textTransform="uppercase"
        as={Link}
        to="/private-jets"
        fontWeight="bold"
      >
        {t('common:nav.jets')}
      </MenuLink>
      <MenuLink
        opacity="0.95"
        color="white"
        textTransform="uppercase"
        as={Link}
        to="/luxury-yachts"
        fontWeight="bold"
      >
        {t('common:nav.yachts')}
      </MenuLink>
      <MenuLink
        opacity="0.95"
        color="white"
        textTransform="uppercase"
        as={Link}
        to="/gallery"
        fontWeight="bold"
      >
        {t('common:nav.gallery')}
      </MenuLink>
      <MenuLink
        opacity="0.95"
        color="white"
        textTransform="uppercase"
        as={Link}
        to="/contact"
        fontWeight="bold"
      >
        {t('common:nav.contact')}
      </MenuLink>
    </Menu>
  )
}

Header.propTypes = {
  variantHeader: PropTypes.oneOf(['solid', 'transparent']),
}

Header.defaultProps = {
  variantHeader: 'solid',
}

export default Header
