import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Flex, Box, Stack, useTheme } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { v4 } from 'uuid'
import ReactPlayer from 'react-player'

import { FeaturedBanner } from '..'
import { H1, H2 } from '../globals'
import Carousel from '../carousel'

export function HeroBody({ children, ...restProps }) {
  const theme = useTheme()

  return (
    <Stack
      justify="center"
      spacing={4}
      width="100%"
      maxWidth={{ lg: theme.breakpoints.mw }}
      paddingRight={{ base: 6, md: 10, xl: 20 }}
      paddingLeft={{ base: 6, md: 10, xl: 20 }}
      pointerEvents="none"
      zIndex={1}
      {...restProps}
    >
      {children}
    </Stack>
  )
}

HeroBody.propTypes = {
  children: PropTypes.node,
}

HeroBody.defaultProps = {
  children: null,
}

export function HeroTitle({ children, ...restProps }) {
  return (
    <H1 color="white" textShadow="0px 0px 5px black" {...restProps}>
      {children}
    </H1>
  )
}

HeroTitle.propTypes = {
  children: PropTypes.node,
}

HeroTitle.defaultProps = {
  children: null,
}

export function HeroSubtitle({ children, ...restProps }) {
  return (
    <H2
      color="white"
      textShadow="0px 0px 5px black"
      fontWeight="light"
      marginTop={0}
      {...restProps}
    >
      {children}
    </H2>
  )
}

HeroSubtitle.propTypes = {
  children: PropTypes.node,
}

HeroSubtitle.defaultProps = {
  children: null,
}

export function HeroCta({ children, ...restProps }) {
  return (
    <Box marginTop={5} width={{ sm: 'fit-content' }} {...restProps}>
      {children}
    </Box>
  )
}

HeroCta.propTypes = {
  children: PropTypes.node,
}

HeroCta.defaultProps = {
  children: null,
}

export function Hero({
  children,
  minHeight,
  image,
  gallery,
  video,
  videoPreview,
  light,
  objectPosition,
  ...restProps
}) {
  const isBrowser = typeof window !== 'undefined'

  const [windowSize] = useState({
    width: isBrowser ? window.outerWidth : '100%',
    height: isBrowser ? window.outerHeight : '100%',
  })
  const [isPlaying, setIsPlaying] = useState(false)

  return (
    <Flex
      backgroundColor="gray.900"
      width="100%"
      overflow="hidden"
      position="relative"
      justifyContent="center"
      alignItems="center"
      minHeight={minHeight}
      {...restProps}
    >
      {children}
      {image && (
        <Box left="0" top="0" width="100%" height="100%" position="absolute" zIndex={0}>
          <FeaturedBanner height={minHeight} position="initial" image={image} />
        </Box>
      )}

      {gallery && (
        <Box left="0" top="0" width="100%" height="100%" position="absolute" zIndex={0}>
          <Carousel autoplay>
            {gallery.map((image) => (
              <FeaturedBanner key={v4()} height={minHeight} position="initial" image={image} />
            ))}
          </Carousel>
        </Box>
      )}

      {video && (
        <Box
          left="0"
          top="0"
          // width={windowSize.width}
          // height={windowSize.height}
          width={{ base: '1280px', lg: '1920px' }}
          height={{ base: '768px', lg: '1080px' }}
          position="absolute"
          zIndex={0}
        >
          <Box position="relative" paddingTop="56.25%" display={{ base: 'none', lg: 'inherit' }}>
            <ReactPlayer
              loop
              muted
              playing
              volume={0}
              controls={false}
              config={{
                youtube: {
                  embedOptions: {
                    rel: '0',
                  },
                },
              }}
              width="100%"
              height="100%"
              style={{ position: 'absolute', top: 0, left: 0 }}
              url={video}
              onBufferEnd={() =>
                setTimeout(() => {
                  setIsPlaying(true)
                }, 3500)
              }
            />
          </Box>
          <Box
            position="absolute"
            top={0}
            left={0}
            width="100vw"
            height="100vh"
            zIndex={3}
            backgroundColor="rgba(0,0,0,0.45)"
          />
          <Box
            display={{ base: 'initial', lg: isPlaying ? 'none' : 'initial' }}
            position="absolute"
            top={0}
            left={0}
            width="100vw"
            height="100vh"
            zIndex={2}
            backgroundImage={`url(${videoPreview})`}
            backgroundPosition="center"
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
          />
        </Box>
      )}
    </Flex>
  )
}

Hero.propTypes = {
  minHeight: PropTypes.any,
  image: PropTypes.node,
  children: PropTypes.node,
  gallery: PropTypes.array,
}

Hero.defaultProps = {
  minHeight: { base: '400px', md: '500px', lg: '600px', xl: '700px' },
  image: null,
  children: null,
  gallery: null,
}
